import React, { Component,Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { SettingsComponent } from '../components/SettingsComponent';
import { CommonStyles } from '../styles/Styles';
import { AppConfig } from '../AppConfig';
import { SimpleTabs } from '../components/Tab';

import { adminData } from '../data/AdminData.js';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppTheme } from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';
import { PopOverV2 } from '../components/PopOverV2';
import { appData } from '../data/AppData';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Input, Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { CustomFormFieldsDialogV2 } from '../components/CustomFormFieldsDialogV2';
import { Toggler } from '../common/components/Toggler'
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Slider from 'react-slick';
import { settings, relativesettings, relativesettingsLess, relativeImageSliderSettings } from './ClientPlace/SliderSettings';
import { Styles as SliderStyles } from './ClientPlace/Styles'


export class CamSettings extends Component {
  constructor(props) {
    super(props);
    this.responseByKey = {}
    this.placeId = this.props.match.params.id ? this.props.match.params.id : null;
    this.camId = this.props.match.params.camid ? this.props.match.params.camid : null;
    this.orgId = (this.props.location && this.props.location.state) ? this.props.location.state.orgId : null
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      showWFH:true,	
      showOffice:false,
      userNotificationList:[],
      showUserNotificationDialog:false,
      dialogTitle:"Add a user",
      anchorEl:null,
      selectedItem:null,
      dialogAction:'',
      confirmDeleteView:false,
      enableFR : false,
      enlargedImage:false,
      imageId:-1,
      query:"",
      output:"",
      genAiInProcess:false,
      latestEventImageUrl:''
    }
    // this.oldObj = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchItems();
    window.addEventListener('click', this.handleImage);
  }

  fetchItems = () => {
    const thisObj = this;
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['cams', thisObj.camId]) }, callback)
        },
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }

        for (const f of AppConfig.camTypes) {
          if (f.key === results[0].type) {
            this.data = f
            break;
          }
        }
        if (!results[0]['officeSettings']) {
          results[0]['officeSettings'] = { ['settings']: {} }
        }

        this.setState({
          cam: results[0],
          fetchState: ApiHelper.State.READY,
        }, () => {
          if (this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eecm' && AppConfig.ENABLE_FR_SETTINGS) {
            this.fetchNotificationUsers()
          }
          if(AppConfig.ENABLE_ADVANCE_SETTINGS){
          this.fetchPlaces()
          }
        })
      }.bind(this))
  }

  fetchNotificationUsers = () => {
    const thisObj = this;
    this.setState({
      fetchState: ApiHelper.State.LOADING
    })
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['faces'], { camId: thisObj.camId }) }, callback)
        },
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        this.setState({
          userNotificationList: results[0],
          fetchState: ApiHelper.State.READY,
          enableFR : this.state.cam && this.state.cam.settings && this.state.cam.settings.webcam1 && this.state.cam.settings.webcam1.webcam1_unauthorizedFace == "YES" ? true:false
        })
      }.bind(this))
  }

  handleTabPanelChange = (value) => {
    if (value == 0) {
      this.setState({
        showWFH: true,
        showOffice: false
      })
    } else {
      this.setState({
        showWFH: false,
        showOffice: true
      })
    }
  }	

  fetchPlaces = () => {

    ApiHelper.call(
      {
        method: "GET",
        endPoint: ApiHelper.makeUrlPath(["places", this.placeId]),
      },
      function (err, result) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err),
          });
          return;
        }
        if (result.cams.length > 0) {
          for (let index = 0; index < result.cams.length; index++) {
            const element = result.cams[index];
            if (element._id == this.state.cam._id) {
              this.setState({
                latestEventImageUrl: element.latestEventImageUrl
              })
              break
            }

          }
        }

      }.bind(this)
    );
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}
    this.setState({
      showUserNotificationDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      errMsg: null
    })
  }

  addFace=(obj)=>{
    this.setState({
      inProcess: true
    })

    if(obj._id){
        this.updateFace()
      }else{
      this.saveFace()
    }
  }

  updateFace = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }

    const thisObj = this;
    let camId = thisObj.camId
    let placeId = this.placeId
    let options = {};
    let data = thisObj.responseByKey
    data['camId'] = camId
    data['placeId'] = placeId
    if (data['alertEnabled']!=undefined) {
      delete data['alertEnabled']
    }
    if (this.validateFace(data)) {

      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['faces', id]),
        jsonBody: data
      }

      Helper.removeNullValues(options.jsonBody)

      ApiHelper.call(
        options,
        function (err, data) {
          if (err) {
            this.setState({
              errMsg: Helper.getErrorMsg(err),
              inProcess: false,
            })
            return;
          } else {
            this.resetToDefaultValues()
            this.fetchNotificationUsers()
          }
        }.bind(this)
      );
    }
  };

  saveFace = () => {
    const thisObj = this;
    let camId = thisObj.camId
    let placeId = this.placeId
    let options = {};
    let data = thisObj.responseByKey
    data['camId'] = camId
    data['placeId'] = placeId
    if (data['alertEnabled'] != undefined) {
      delete data['alertEnabled']
    }
    if(this.validateFace(data)){
    options = {
      method: "POST",
      endPoint: ApiHelper.makeUrlPath(["faces"]),
      jsonBody: { faceData: data }
    };
    ApiHelper.call(
      options,
      function (err, data) {
        if (err) {
          this.setState({
            errMsg: Helper.getErrorMsg(err),
            inProcess: false,
          })
          return;
        } else {
          this.resetToDefaultValues()
          this.fetchNotificationUsers()
        }
      }.bind(this)
    );
  }
  };

  validateFace = (data) => {
    if (!data.name) {
      this.setState({
        errMsg: "Please enter name",
        inProcess: false,
      })
      return false

    } else if (!data.type) {
      this.setState({
        errMsg: "Please enter type",
        inProcess: false,
      })
      return false

    } else if (!data.image || data.image.length == 0) {
      this.setState({
        errMsg: "Please upload images",
        inProcess: false,
      })
      return false

    }
    return true
  }

  deleteUser = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }

    this.setState({
      inProcess: true
    })

    const options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['faces', id]),
    }

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchNotificationUsers()
      }
    }.bind(this))
  }

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false,
    });
  };

  renderRelativeImageSlider = (item) => {
    if (!item) return

    const eventImages = item.image
    // if (this.props.relativeImageSliderMode) {
      return (
        <div className={css(SliderStyles.imageSliderContainer)}>
          {/* {this.props.relativeImageSliderMode && */}
            <div className={css(SliderStyles.eventLiveFeed)} onClick={() => this.closeRelatedImages()}>
              <img src={require('../assets/images/close-img-white.svg')} className={css(CommonStyles.addImg)} />
            </div>
          {/* } */}

          <Slider
            {...relativeImageSliderSettings}
            className={css(SliderStyles.slider)}
            initialSlide={item._id} >
            {eventImages.length > 0 &&
              eventImages.map((option, i) => {
                return (
                  <div key={i} className={css(SliderStyles.eventImageSpecContainer)}>
                    <img
                      src={option}
                      key={option}
                      className={css(SliderStyles.eventImageSpec)} />
                  </div>
                )
              })}
          </Slider>
        </div>
      )
    // }
  }

  renderUserNotificationDialog = () => {
    const formFields = appData.userNotificationFields
    return (
      <CustomFormFieldsDialogV2

        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.showUserNotificationDialog}
        dialogTitle={this.state.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        // errMsg={this.state.errMsg}
        inProcess={this.state.inProcess}
        action={this.state.dialogAction}
        formFields={formFields}
        showConfirmDeleteView={this.showConfirmDeleteView}
        multi={true}
        deleteItem={() => this.deleteUser()}
        responseByKey={this.responseByKey}
        // enableValidation={this.state.dialogAction === 'delete' ? false : true}
        errMsg={this.state.errMsg}
        onSuccess={this.addFace}
      // formDisabled={this.state.dialogAction === 'delete' ? true : false}

      >
      </CustomFormFieldsDialogV2>
    )
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  onPopOverClick = (key) => {
    if (key === 'edit') {
      this.showUpdateUserDialog()
    } else if (key === 'delete') {
      this.showDeleteUserDialog()
    }
  }
  
  showDeleteUserDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      showUserNotificationDialog: true,
      dialogTitle: 'Delete a user',
      dialogAction: 'delete',
    })
  }

  showUpdateUserDialog = () => {
    this.responseByKey = this.state.selectedItem
    // this.oldObj = JSON.parse(JSON.stringify(this.state.selectedItem))
    this.setState({
      anchorEl: null,
      showUserNotificationDialog: true,
      dialogTitle: 'Edit a user',
      dialogAction: 'edit',
    })
  }

  editClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: item
    })
  }

  editClickClose = () => {
    this.setState({ anchorEl: null })
  }

  enlargeImage = (index) =>{
    this.setState({
      enlargedImage : !this.state.enlargedImage,
      imageId: index
    })
  }

  enlargeImageForGenAI = () => {
    this.setState({
      enlargedImage : !this.state.enlargedImage,
    })
  }

  componentWillUnmount() {
    // Remove the click event listener when the component unmounts to prevent memory leaks
    window.removeEventListener('click', this.handleImage);
  }

  handleImage = (event) => {
    if(event.target.localName == "img")return
    this.setState({
      enlargedImage: false,
      imageId: -1,
      output:""
  })

  }

  genAITest = () => {
    return (<>
      <div className={css(Styles.genAIContainer)}>

        {/* <div onClick={() => this.enlargeImageForGenAI()} align='center' > */}

        {/* </div> */}
        <div className={css(Styles.frMainContainer)}>
          <FormControl fullWidth>
            <TextField
              placeholder={'Enter Query'}
              type='text'
              label="Enter Query"
              onChange={(e) => {
                this.setState({
                  query: e.target.value
                })
              }}
              {...this.props}
              className={css(Styles.inputCss)}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: css(Styles.textFieldContentInput),
                  input: css(Styles.inputText),
                }
              }}
              InputLabelProps={{
                classes: {
                  root: css(Styles.textFieldContent),
                }
              }}
            />
          </FormControl>

          <Button
            disabled={this.state.genAiInProcess}
            variant='contained'
            onClick={() => this.testGenAI()}
            className={css(Styles.buttonStyle)}
          // style={{marginTop:"16px"}}
          >
            Try generative AI
            {this.state.genAiInProcess && <CircularProgress color='primary' size={20} className={css(Styles.circulerProgress)} />}

          </Button>

        </div>
        <h4>{this.state.output}</h4>
        {this.state.cam.settings&& this.state.cam.settings.genAISettings && this.state.cam.settings.genAISettings.image_resize && <h4>Image size selected :- {this.state.cam.settings.genAISettings.image_resize}</h4>
        }
        <img
          style={{
            // overflow: 'hidden',
            // transition: 'transform 0.3s',
            // transform: this.state.latestEventImageUrl && this.state.enlargedImage ? 'scale(6)' : 'scale(1)',
            // position: "relative",
            // zIndex: this.state.enlargedImage ? 10000 : 'unset',
            // objectFit: 'scale-down'
            width: "100%"
          }}
          alt='no image found'
          // height='200px'
          // width='300px'
          src={this.state.latestEventImageUrl} />

      </div>
    </>)
  }

  userTable = () => {
    return (
      <>
        <div className={css(Styles.frMainContainer)}>
          {this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eecm' && this.state.showOffice &&
            <FormControlLabel
              color='secondary'
              className={css(Styles.formControlLabelContainer)}
              label={"Face Training"}
              labelPlacement={'start'}
              control={
                <Switch
                  classes={{
                    track: this.state.enableFR && css(Styles.switchControlLabeltrack),
                    checked: css(Styles.switchControlLabelChecked),
                    thumb: css(Styles.switchControlLabelThumb),
                  }}
                  checked={this.state.enableFR}
                  onChange={this.enableFRChange}
                />
              }
            />
          }
          {this.state.enableFR && this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eecm' && this.state.showOffice &&
            <div className={css(Styles.shiftRight)}>
              <Button
                // disabled={this.state.inProcess || !this.state.enableFR}
                variant='contained'
                onClick={() => this.showAddUserNotificationDIalog()}
                className={css(Styles.buttonStyle)}>
                ADD USER
              </Button>
            </div>

          }
        </div>
        {this.state.enableFR &&
          <Table className={css(Styles.tableOuterContainer)}>
            <TableHead className={css(Styles.tableHead)}>
              <TableRow className={css(Styles.tableRowHeader)}>
                <TableCell
                  align='center'
                  className={css(Styles.tableHeader)}>
                  <p>SL.No</p>
                </TableCell>


                {adminData.userNotificationFields.map((user, i) => {
                  return (
                    <TableCell key={i}
                      align='center'
                      className={css(Styles.tableHeader)}>
                      {user.name}
                    </TableCell>
                  )
                })}

              </TableRow>
            </TableHead>

            <TableBody>

              {this.state.userNotificationList && this.state.userNotificationList.length > 0 && this.state.userNotificationList.map((item, index) => {
                return (<TableRow key={index} className={css(Styles.tableRow)}>
                  <TableCell className={css(Styles.tableItem)}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {index + 1}
                    </div>
                  </TableCell>
                  <TableCell align='center' className={css(Styles.tableItem)}>{item.name}</TableCell>
                  <TableCell align='center' className={css(Styles.tableItem)}>{item.type && item.type.toLowerCase() == 'blacklisted' ? 'BLOCKED' : item.type && item.type.toLowerCase() == 'whitelisted' ? 'AUTHORIZED' : item.type}</TableCell>
                  <TableCell onClick={() => this.enlargeImage(item._id)} align='center' className={css(Styles.tableItem)}><img
                    style={{
                      overflow: 'hidden',
                      transition: 'transform 0.3s',
                      transform: this.state.enlargedImage && this.state.imageId == item._id && item.image ? 'scale(6)' : 'scale(1)',
                      position: "relative",
                      zIndex: this.state.enlargedImage && this.state.imageId == item._id ? 10000 : 'unset',
                      objectFit: 'scale-down'

                    }}
                    alt='no image found' height='50px' width='50px' src={item.image[0]} /> {Array.isArray(item.image) && item.image.length > 1 ? <span>+ {item.image.length - 1}</span> : ""}</TableCell>
                  <TableCell align='center' className={css(Styles.tableItem, Styles.prewrap)}>
                    <Fragment>
                      <PopOverV2
                        id={index}
                        boxShadowLight={true}
                        open={Boolean(this.state.anchorEl)}
                        items={appData.popOverFields}
                        anchorEl={this.state.anchorEl}
                        onPopOverClick={this.onPopOverClick}
                        editClickClose={this.editClickClose}
                      />  </Fragment>
                    <div className={css(Styles.editContainer)}>
                      <div onClick={(e) => this.editClick(e, item)} className={css(Styles.editContent)} >
                        <img src={require('../assets/images/more-rounded.svg')} />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>)
              })}

            </TableBody>
          </Table>
        }
      </>

    )
  }

  showAddUserNotificationDIalog = () =>{
    this.setState({
      showUserNotificationDialog : true,
      dialogTitle:"Add a user",
      dialogAction:'add'
    })
  }

  testGenAI = ()=>{
    this.setState({
      genAiInProcess:true
    })
    const params = {
      image : this.state.latestEventImageUrl,
      query:this.state.query,
      detail:this.state.cam.settings.genAISettings.modelDetail
    };
    let options = {}
      options = {
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['camevents',"testGenAI"], params),
      }

      ApiHelper.call(options, function (err, data) {
        if (err) {
          const msg = err.message ? err.message : Helper.getString('defaultErrorMsg')
          this.setState({
            errMsg: msg,
            errCode: err.respCode,
            genAiInProcess:false
          })
          return
        }
        this.setState({
          errMsg: '',
          errCode:'',
          output:data,
          genAiInProcess:false
 
        })
      }.bind(this))
    
  }

  enableFRChange = () =>{
    this.setState({
      enableFR: !this.state.enableFR
    }, () => {
    let camData = {
      enableFR : this.state.enableFR,
      enableUnauthorize:true
    }
    const options = {
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['cams', this.state.cam._id]),
      jsonBody: {
        camData: camData
      }
      
    }

    Helper.removeNullValues(options.jsonBody.camData)

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          respMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      }

      let cam = this.state.cam
      cam.settings.webcam1.webcam1_unauthorizedFace = this.state.enableFR ? "YES":"NO"

      this.setState({
        respMsg: Helper.getString('settingsUpdated'),
        inProcess: false,
        cam :cam
      })

    }.bind(this))
  })
  }

  readyView = () => {
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          backPathName={'/places/' + this.placeId + '/cams'}
          statSuffix={ this.state.cam && this.state.cam.type == 'alert' ? 'CAM SETTINGS' : 'AGENT SETTINGS'}
          camId = {this.state.cam._id}
          title={this.state.cam.name}
          camType = {this.state.cam.type ? this.state.cam.type :""}
          latestEventImageUrl = {this.state.latestEventImageUrl ? this.state.latestEventImageUrl  : ""}
        />
        <div className={css(CommonStyles.innerMainContainer)}>
          <div className={css(Styles.innerMainContent)}>
          {this.state.cam.type == 'general' && <SimpleTabs numberOfTabs={['Work From Home', 'Work From Office']} handleTabPanelChange={this.handleTabPanelChange}></SimpleTabs>}
          {this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eecm' && <SimpleTabs  tabVal={this.state.showOffice == true ? 1 : 0 } numberOfTabs={['Alert Settings', 'Face Recognition']} handleTabPanelChange={this.handleTabPanelChange}></SimpleTabs>}
          {this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eevms' && <SimpleTabs  tabVal={this.state.showOffice == true ? 1 : 0 } numberOfTabs={['Cam Settings', 'Test Gen AI']} handleTabPanelChange={this.handleTabPanelChange}></SimpleTabs>}

            {this.data && this.data.features && this.state.showWFH &&
              <SettingsComponent
                data={this.data}
                item={this.state.cam}
                itemId={this.camId}
                itemName={'cams'}
                apiBodyPrefix={'camData'}
                camSettingClass={true}
              />
            }

            {
              this.state.showOffice && this.state.cam.type != 'alert' &&
              <SettingsComponent
                data={this.data}
                item={this.state.cam.officeSettings}
                itemId={this.camId}
                itemName={'cams'}
                apiBodyPrefix={'camData'}
                showOffice={this.state.showOffice}
                camSettingClass={true}

              />
            }

            {/* {this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eecm' && this.state.showOffice && <Toggler
              defaultMode={this.state.enableFR}
              title={'Facial Recognition'}
              toolTip={'Facial Recognition'}
              onChange={this.enableFRChange}
            />
            } */}
            {  this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eecm' && this.state.showOffice &&
              this.userTable()
            }
              {  this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eevms' && this.state.showOffice &&
              this.genAITest()
            }

            {this.state.enableFR && this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eecm' && this.state.showOffice && this.renderUserNotificationDialog()}

            {!this.data &&
              < p className={css(Styles.emptyDataContainer)}>{Helper.getString('noItemsFound')}</p>
            }

          </div>
        </div>
      </div>
    )
  }
}


const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    margin: '40px 0px 50px 0px',
    '@media(max-width: 768px)': {
      width: '90%',
    }
  },
  emptyDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flex: 1,
  },
  shiftRight:{
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1rem 0px'
  },
  tableHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    flex: 1,
    minWidth: 'unset',
    // color: AppTheme.white,
    color:AppTheme.primaryBase
  },
  tableRowHeader: {
    // backgroundColor: '#202633',
    backgroundColor:"#fff",
    borderRadius: '4px 4px 0 0',
  },
  tableRow: {
    // backgroundColor: '#202633',
    backgroundColor:"#fff",
    borderRadius: '4px 4px 0 0',
    cursor: 'pointer',
    ':hover': {
      backgroundColor:"#fff",
    }
  },
  tableItem: {
    // color: AppTheme.white,
    color:"#000",
    fontWeight: 'bolder'
  },
  tableOuterContainer: {
    backgroundColor:"#fff"
  },
  disableTable:{
    pointerEvents:"none",
    opacity:"0.7"
  },
  tableHead: {
    marginTop: 10,
    // backgroundColor: AppTheme.primaryBackground,
    backgroundColor:"#fff"
  },
  eventListImageContainer: {
    width: 100,
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  eventListImageConatinerSecondary: {
    marginTop: 5
  },
  eventImg: {
    width: '100%'
  },
  pageNumber: {
    opacity: 0.7,
    color: AppTheme.white,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      fontSize: 15,
    }
  },
  refreshIconContainer: {
    padding: '0px 12px',
    ':hover': {
      backgroundColor: AppTheme.primaryBackground,
    },
    '@media (max-width: 767px)': {
      padding: '0px 5px',
    }
  },
  refreshIcon: {
    borderRadius: '50%',
    cursor: 'pointer',
    opacity: 0.5,
    color: AppTheme.white,
    ':hover': {
      backgroundColor: '#1D2331',
    }
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  downloadImage: {
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    '@media (max-width: 767px)': {
      paddingLeft: 5,
      paddingRight: 5,
    }
  },
  formOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: AppTheme.primaryBackground,
    padding: 20,
    paddingTop: 0,
    paddingBottom: 0
  },
  innerContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
    marginBottom: 50,
  },
  formInnerContainer: {
    display: 'flex',
    flex: 1,
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: AppTheme.secondaryColor,
    marginTop: 10,
    overflowX: 'scroll'
  },
  eventContainerHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: AppTheme.primaryBackground,
    padding: 10,
    borderRadius: '4px 4px 0 0',
  },
  eventContainerHeaderSpec: {
    // backgroundColor: AppTheme.secondaryColor,
    padding: 0,
  },
  downloadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  dialogTitle: {
    padding: 20,
    backgroundColor: AppTheme.white
  },
  dialogTitleContent: {
    fontWeight: 600,
    fontSize: 16,
    margin: 0,
    color: AppTheme.primaryBackground,
  },
  dialogContent: {
    padding: 0,
    backgroundColor: AppTheme.primaryBackground,
    marginBottom: -1
  },
  dialogContentData: {
    margin: 0,
    padding: '15px 20px 0px 20px',
    fontWeight: 600,
    opacity: 0.6,
    color: AppTheme.white,
  },
  dialogActions: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: AppTheme.primaryBackground,
  },
  dialogActionButton: {
    fontWeight: 'bolder',
    margin: 0,
    marginRight: 10,
    fontSize: 15,
    textTransform: 'unset',
    backgroundColor: ColourPalette.color3,
    color: AppTheme.white,
    padding: '5px'
  },
  headerContainer: {
    fontSize: 18,
    fontWeight: 'bold',
    flex: 1,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    color: AppTheme.white,
    '@media (max-width: 767px)': {
      fontSize: 16,
    }
  },
  downloadIcon: {
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
    // color: ColourPalette.color1,
    color:"rgb(255, 255, 255) !important",
    '@media (max-width: 767px)': {
      paddingLeft: 5,
      paddingRight: 5,
    }
  },
  dialog: {
    minWidth: 500,
  },
  progressContainer: {
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progress: {
    color: ColourPalette.color1
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    fontWeight: 'bolder'
  },
  tagValue: {
    margin: 0,
    marginBottom: 5
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  tagContainer: {
    border: '1px solid #566380',
    marginTop: 10,
    marginRight: 15,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tagContainerDetail: {
    border: '1px solid #6c6c6c',
    borderRadius: 5,
  },
  tagKey: {
    backgroundColor: ColourPalette.color1,
    margin: 0,
    color: AppTheme.primaryBackground,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
    borderRadius: '4px 0px 0px 4px',
    cursor: 'default'
  },
  tagValueVariant: {
    margin: 0,
    color: '#C7C9CB',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 4,
    cursor: 'default'
  },
  eventEditContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  eventEditContainerStyle: {
    marginTop: 10,
    flex: 1,
  },
  menuHeaderRoleConatiner: {
    cursor: 'pointer',
    backgroundColor: ColourPalette.color1,
    marginRight: 5,
    marginLeft: 5,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    maxWidth: 60,
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      marginTop: 5
    }
  },
  menuHeaderRole: {
    margin: 0,
    color: AppTheme.primaryBackground,
    fontSize: 13,
    fontWeight: 'bold'
  },
  inputLabelRoot: {
    color: AppTheme.white
  },
  inputLabelFocused: {
    color: AppTheme.white
  },
  prewrap: {
    whiteSpace: "pre-line"
  },
  // buttonStyle: {
  //   backgroundColor: ColourPalette.color3,
  //   borderRadius: 4,
  //   color: AppTheme.white,
  //   fontWeight: 'bold',
  //   fontSize: 11,
  //   maxHeight: AppTheme.actionHeaderbarHeight
  // },
  buttonStyle: {
    marginRight: 8,
    backgroundColor: ColourPalette.color3,
    borderRadius: 4,
    color: AppTheme.white,
    fontWeight: 'bold',
    fontSize: 11,
    padding: '5px 16px'
  },
  editContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  editContent: {
    cursor: 'pointer',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      // backgroundColor: AppTheme.secondaryColor
      background:"rgba(0, 0, 0, 0.07)"

    }
  },
  formControlLabelContainer: {
    margin: '1rem 0px !important'
  },
  switchControlLabel: {
    color: '#377bac',
    margin: 0,
    fontSize: 14,
    opacity: 0.6
  },
  switchControlLabelChecked: {
    padding: 10,
    color: '#377bac',
  },
  switchControlLabelThumb: {
    width: 18,
    height: 18
  },
  switchControlLabeltrack: {
    backgroundColor: '#377bac'
  },
  frMainContainer:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width:"100%",
    gap:"1rem"
  },
  genAIContainer:{
    display: "flex",
    flexDirection:"column",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop:"1rem",
    gap:"12px"
  },
  inputCss: {
    flex: 1,
    // color: AppTheme.white
    color:"#000"
  },
  textFieldContentInput: {
    borderRadius: 4,
    // backgroundColor: AppTheme.secondaryColor,
    backgroundColor:"rgb(238, 238, 238) !important",
    color:"#000",
    paddingLeft: 8,
    // color: AppTheme.white,
  },
  textFieldContent: {
    // color: AppTheme.white,
    zIndex: 1,
    paddingLeft: 20,
    opacity: 1,
    color:'#000'
  },
  inputText: {
    textAlign: 'center',
    border:"1px solid #000"
  },
  inputLabelCss: {
    // color: AppTheme.white
  }
})